

	.Instructors{
		text-align: center;
		h4{
			font-size: 14px;
			margin: 0;
			text-transform: uppercase;
		}
		span{
			color: $brand-primary;
		}
		.content{
		    transition: 0.5s;
		    background: #f9f9f9;
			p{
			    // padding: 15px 0 24px;
			}
		}
		.content-title{
			a h4{
				transition: 0.5s;
				&:hover{
					color: $brand-primary;
				}
			}
		}
		.read-more{
			display: block;
			margin-bottom: 10px;
			color: $text-color;
			font-size: 12px;
			font-weight: 600;
			&:hover{
				color: $white-color !important;
				text-decoration: underline !important;
			}
		}
		.title-section{
			padding: 0 15px 40px;
			p:before{
				display: none;
			}
		}
		.block-content{
			position: relative;
			transition: 0.5s;
			&:before{
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				transition: 0.5s;
				background: rgba(0, 0, 0, 0.5);
			}
			.content{
				position: absolute;
				width: 100%;
				bottom: -1px;
			    height: 78px;
				// bottom: -108px;
			    // max-height: 109px;
			    transition: 0.5s;
			    overflow: hidden;
			}
			.content-text,.instructors-media-link{
				opacity: 0;
				transition: 0.5s;
			}
		}
		.block-content:hover{
			transform: translateY(0);
			border-bottom: 2px solid $brand-primary;
			&:before{
				opacity: 1;
			}
			.content{
			    height: 160px;
			}
			.content-text,.instructors-media-link{
				opacity: 1;
				transition: 0.5s;
			}
		}
		nav{
			padding-left: 15px;
			a:hover{
				i{
					color: $white-color;
				}
			}
		}
	}

	.Instructors-page-slider{
		.items{
			padding: 0 15px;
		}
	    .owl-controls{
	        padding: 0 40px;
	        transform: translateY(0%);
	        text-align: left;
		    bottom: 0;
		    top: inherit;
	    }
	    .owl-next{
	        float: right;
	    }
	    .list-group .description{
	        display: block;
	    }
	}

	// Instructors Slider page

	.mis-stage{
		height: 540px !important;		
	}

	.mis-slider{
		background-color: transparent;
	}

	.mis-slide{
	    padding: 0 !important;		
	}

	.mis-slider li img{
		border-radius: 0;
		margin: 0 auto;
		max-width: inherit;
	}

	.mis-slider li a{
		display: inline-block;
	}

	.mis-slide .block-content:hover .content{
	    height: 150px !important;
	}

	.mis-slide {
		h4, span{
			font-size: 18px;
			transition: 5s !important;
		}
	}

	.mis-current{
		h4, span{
			font-size: 18px;
		}
	}

	.mis-nav-buttons a{
	    color: #F9F9F9;
	}

	.mis-nav-buttons{
		opacity: 1 !important;
	}

	.mis-nav-buttons{
		top: inherit;
		bottom: 28px;
	}

	.mis-nav-buttons a{
	    padding-top: 0 !important;
	    height: 50px !important;
	}

	.mis-nav-list{
		z-index: 10;
	}

	.mis-nav-list li.mis-current a, 
	.mis-nav-list li a:hover{
	    opacity: 1;
		background: #00b3ee;
		box-shadow: inset 0 0 2px #00b3ee;
	}

	.mis-slide .block-content:hover .content-text p{
	    padding: 1px 0 7px !important;
	}

	.mis-slide .instructors-media-link{
		padding-top: 0;
	}

	.mis-slide .block-content:before{
		background: rgba(0, 0, 0, 0.4);
	}

	.mis-slide .block-content:before{
		opacity: 1;
	}

	.mis-current .block-content:before,
	.mis-current .block-content:hover:before{
		opacity: 0;
	}

/*--------------------  
	instructor Page 
---------------------*/

	.instructor-table{
		padding-top: 20px;
		h4{
			text-transform: uppercase;
			margin-bottom: 15px;
		}
	}

	.single-instructor img{
		width: 100%;
	}

	.single-instructor{
		.sidebar-title-section{
			span{
				display: block;
				font-size: 14px;
				margin-top: 5px;
				color: $brand-primary;
			}
		}
	}

/*--------------------  
	instructor-table  
---------------------*/
.instructor-table{
	thead tr th{
	    border-bottom: 0 !important;
	}
	.table > tbody > tr > td{
		border: 0;
	}
}

.table{
	text-align: left;
	background: #f9f9f9;
	th, td{
		padding: 18px 8px !important;
	}
	thead .table-title{
		font-family: $font-family-sans-serif-montserrat;
	}
	.column-id{
	    font-weight: 500;
		text-transform: uppercase;
		padding-left: 25px !important;
	}
	.table-title{
		color:$white-color;
		background: $brand-primary;
		text-transform: uppercase;
	}
	tbody tr th,
	tbody tr td{
        color: #797979;
        font-weight: 500;
	    border:0;
	}
	tbody tr{
		transition: 0.5s;
	    border-top: 1px solid #e8e8e8;
	}
	tbody tr:hover{
		background: $brand-primary;
		th,td{
			color:$white-color;
		}
	}

}





