

	.single-blog{
		.blog-title{
			padding: 20px 0 7px;
			h3{
				text-transform: uppercase;
				font-size: 20px;
			}
		}
		.entry-meta{
			padding: 0;
			margin: 0;
			border: 0;
			// text-align: left;
		}
		.share_section{
			padding-top: 40px;
			margin-top: 12px;
			.media-link{
				padding-bottom: 0;
				margin: 0;
			}
		}
		.media-section{
			padding: 20px 0;
		    margin: 0;
		}
	}