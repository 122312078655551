
/*=============================================
=            Responsive style            =
=============================================*/

    /* Custom, iPhone Retina */ 
    @media only screen and (min-width : 320px) {
        
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 480px) {

    	// help class

		.block-content{
			margin: 0 0 20px;
			img{
				// width: auto;
			}
			h6{
				line-height: 24px;
			}
		}

		.content{
			padding: 34px 40px 10px;
			p{
				margin: 0;
			}
		}


		/*=====
			* Home page 
		=======*/

		// home page

		
		// call to action
		.call_to_action{
			br{
				display: inline-block;
			}
		}

		/*======
			* About page
		========*/
			
			// tab
			.tab-content {
				img{
					min-width: auto;
					margin: 0 auto;
				}
				p{
					padding: 2% 0;
				}
			}

			// accordion
			.rex-accordion{
				i{
					display: inline-block;
					float: right;
					transform: translateY(7px) rotate(45deg);
					opacity: 0;
					transition: 0.10s;
				}
				h5{
					display: inline-block;
				}
				.rx-panel-heading{
					position: relative;
					&:before{
						content: "";
						position: absolute;
						top: 50%;
						right: 15px;
						width: 11px;
						height: 3px;
						background: #797979;
						opacity: 1;
						transition: 0.10s;
					}
				}
				.collapsed{
					&:before{
						opacity: 0;
						right: 0;
					}
					i{
						opacity: 1;
						transform: translateY(7px) rotate(0deg);
					}
				}
			}

		// course page
		.single-courses-img{
		    margin: 0 auto 39px;
		}

    }

    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {


		aside.coures-reat{
			padding: 15px 11px;
		}

		aside.widget{
			ul li{
				padding: 15px 18px 15px 35px;
			}
		}

    	// help class
		.owl-controls{
		    position: absolute;
		    top: 0;
		    right: 0;
		    transform: translateY(-105px);
		}

		.tab-content {
			p{
			    padding: 1% 0;
			}
		}

		.media-section{
			padding-top: 20px;
			margin-bottom: 46px;
		}

		.modal-dialog{
			min-width: inherit;
		}
		.modal-content .modal-body{
			padding: 0 40px;
			.rex-forms{
			    transform: translateX(0);
			}
		}

		.author-description {
			.media-body{
			    display: table-cell;
			    padding-left: 23px;
			    padding-top: 0;
			}
		}

		.breadcum h4{
			font-size: 20px;
		}

		.single-course-img{
		    margin: 6px auto 40px;
		}

		.rex-overlay{
			top: 175px;
		}

		.rex-slider{
			p{
				display: block;
			}
		}

		// Nav Search styel

		.search-input{
		    padding: 0 75px;
		}
		.menu-search {
			.search-btn{
				height: 100%;
			}
			.search-input input{
			    padding: 0 60px 0 20px;
			}
		}

		.menu-search {
			.search-input {
				input{
					font-size: 14px;
				}
			}
		}



		// slider
		
		.rex-slider{
			h1{
				top: 40% !important;
			}
			.slider-text{
				top: 40% !important;
			}
		}

		// call to action
		.call_to_action{
		    padding: 44px 0 59px;
			p{
				font-size: 24px;
				line-height: 34px;
			}
		}

		.search-full-width{
			input{
				font-size: 18px;
			}
		}

		// testimonials

		.testimonials{
			img{
				margin: 0 auto;
				// margin-right: 50px;
				float: none;
			}
			.testimonials-body{
				display: block;
				width: 100%;
				padding-bottom: 8px;
			}

			.testimonials-img{
				min-width: 200px;
			    display: table-cell;
			}
			.testimonials-body{
				display: table-cell;
			}
		}

		// features section
		.features-list{
			margin-bottom: 0;
		}

		/*=========
			* About page style
		=========== */
		.About_Us{
			br{
				display: block;
			}
		}
		// tab
			.rex-accordion{
				padding-top: 10px;

				.rx-panel-heading{
					padding: 16px 35px;
					padding-right: 15px;
				}
			}
			
		// accordion
		.rex-accordion{
			.panel-body{
				border-top-color: transparent !important;
			}
		}
		/*=======
			courses.html			
		=========*/

		.courses_page {
			.block-content {
				h6{
					font-size: 18px;
				}
			}
		}
		
		.courses_page_btn{
			margin-right: 15px;
			margin-bottom: 20px;
		}

		.popular_courses{
		    padding: 50px 0 37px;
		}

		.courses-slider{
			.owl-controls{
			    top: inherit;
			    width: auto;
			    right: 50%;
			    transform: translateX(50%);
			}
		}


		/*=======
			Instructors			
		=========*/
		#Instructors,
		#latest_news{
			padding: 0 20%;
		}

		.Instructors{
			.block-content {
				.content{
				    height: 100px;
				}
			}
			.Instructors-last-child{
				margin-bottom: 10px;
			}
			.block-content:hover{
				.content{
					height: 155px;
				}
			}
		}

		/*=======
			newsletter			
		=========*/



		/*=======
			footer			
		=========*/
		footer {
			.usefull-link li{
	            min-width: 15%;
		        padding: 2px 0;
			    margin-right: 20px;
			}
			.address{
				display: inline-block;
			}
			.faq-section{
				li{
					display: block;
				}
			}
			.footer-description {
				p{
					display: block;
					padding-top: 15px;
					padding-bottom: 0;
				}
			}
		}
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {

    	section{
    		text-align: inherit;
    	}

    	aside.widget{
    		h1,h2,h3,h4,h5,h6{
    			&:before{
    				left: 15px;
    				transform: translateX(0%);
    			}
    		}
    	}

		.display-none{
			display: inline;
		}

    	.author-top{
    		display: none;
    	}

    	.author-bottom{
    		display: inline-block;
    	}

		.title-section{
			padding-bottom: 30px;
		}

		.search{
			padding-left: 15px;
			padding-right: 15px;
		}

		.single-course .description{
		    padding-top: 0;
		}

		.addres-icon{
			margin-bottom: 20px;
			i{
				font-size: 18px;
				color: $brand-primary;
			}
		}

		.gallery-img{
			padding-left: 15px;
		}

		/* REVIEWS Section */

		.comments-title,
		.review-form p,
		.select-rating,
		.rating{
			display: inline-block;
		}
		.review-form p{
			padding-left: 6px;
		}
		.rating{
			float: right;
		}

		.select-rating{
			margin-left: 25px;
		}
		/*-------*/


		.featured-section{
			.instructor-content{
				padding: 15px 10px;
				&:last-child{
					padding-bottom: 0;
				}
			}
		}

		.course-reating{
			.rex-bottom-medium{
				width: 100%;
			}
		}

		.instructor{
			padding-top: 10px;
			padding-left: 15px;
		}


    	.rex-bottom-medium{
			height: 52px;
			line-height: 52px;
    	}

    	.About_Us {
    		.rex-bottom-medium{
			    margin-top: 8px;    			
    		}
    	}

		.entry-meta{
			padding-top: 10px;
			text-align: center;
			padding-top: 15px;
			margin-top: 20px;
			border-top: 1px solid $gray-lignt;
		}

		.author{
			padding: 10px 0;
			display: inline-block;
			padding-top: 0;
			padding-bottom: 0;
			p{
				margin: 0;
			}
		}
		.date{
			display: inline-block;
		}
		.rex-comments{
			float: none;
		}
		.author,
		.categories-links{
		    margin-right: 4px;
		    padding-right: 6px;
			border-right: 1px solid $gray-lignt;			
			p{
				margin: 0;
				line-height: 1;
			}
		}

		.comments{
			.comments-img{
				img{
					float: left;
					margin-right: 15px;
				}
			}
			.comments-content{
				overflow: hidden;
			}
		}

		.breadcum{
			text-align: left;
			h4{
				font-size: 25px;
			}
		}
    	.pages{
    		text-align: center;
    	}

    	.block-content{
    		margin: 0;
    	}
		.content{
		    padding: 30px 20px 10px;
		}

		.block-title{
			padding: 0;
		}
		.categories-links, .rex-comments{
			display: inline-block;
		}

		.share_section{
			.col-md-6{
				padding: 0;
			}
		}

		.quote{
			p br{
				display: none;
			}
		}

		.search-full-width{
			.col-md-7{
				padding-left: 0;
			}
		}

		.menu-search {
			.search-btn{
				height: 100%;
			}
		}

		.features-list{
			padding: 80px 0;
		}

		// nav
		.menu-search{
			position: absolute;
			top: 50%;
			text-align: right; 
			padding: 0;
			height: 0;
			&:before{
				content: "";
				position: absolute;
				left: 0;
				top: -5px;
				opacity: 0;
				width: 1px;
				height: 14px;
				background: $text-color;
			}
			.btn-default{
				margin-top: -14px;
				padding-right: 0;
				position: relative;
				&:before{
					content: "";
					position: absolute;
					left: 0;
					top: 50%;
					width: 1px;
					height: 14px;
					background: $text-color;
					transform: translateY(-50%);
				}
			}
			.search-input{
				left: 50%;
				top: 0;
				width: 49%;
				margin: 0 auto;
				transform: translate(0%, 20%);
				// transform: translate(-33%, 20%);
				input{
					height: 40px !important;
				}
			}
			.overlay{
			    top: -24px;
			}
		}

		.main-menu{
			padding: 10px 0;
		}

		.logo{
			float: left;
		}

		ul.main-ul{
		    width: auto;
		    // float: right;
		    margin-right: 15px;
	        padding-left: 28%;
			li {
				a{
					padding: 31px 20px
				}
				ul{
					box-shadow: rgba(239, 239, 239, 0.568627) 0px 1px 0px 1px;
					margin-top: 10px;
				}
				ul>li{
					ul{
						box-shadow: none;
					}
				}
			}
		}

		.menu > ul > li > ul > li > ul > li{
			border-bottom: 1px solid $gray-lignt;
		}

		.menu > ul > li > ul > li a{
		    padding: 0.2em 0;
		}

		.menu-container{
		    min-height: 85px;
		}

		.nav-tabs{
			li{
				width: 49%;
			}
		}

		.review{
			padding-top: 30px;
		}

		/*=======
			* Home page style
		=========*/

		// header section
	  	header{
			.block{
				text-align: left;
				a{
					padding-right: 10px;
					margin-right: 10px;
					border-right: 1px solid $gray-lignt;
					display: inline-block;
				}
			}
			.align-right{
				text-align: right;
			}
		}

		// slider
		.rex-slider{
			h1{
				font-size: 40px;
				line-height: 50px;
				top: 30% !important;
			}
			p{
				display: block;
				font-size: 24px;
				line-height: 34px;
			}
			a{
				display: inline-block;
			}

			.slider-btn{
				padding-top: 20px;
			}
			.slide-center{
				h1{
					line-height: 29px;
				}
			}
			.slider-text{
				top: 32% !important;
			}
			.slider-btn{
				top: 60% !important;
				width: 100%;
				text-align: center;
			}
			.slide-center{
				h1{
					top: 44% !important;
				    line-height: 22px;
				}
				.slider-btn{
					top: 52% !important;
				}
			}
		}
		.highlight-text{
			font-size: 24px;
		}


		// about section
		.About_Us{
			p{
			    padding: 20px 15px 20px 15px;
			}
			img{
				padding-top: 30px;
			}
			.about-content{
				padding-left: 2%;
				padding-top: 2%;
				h2{
					margin-top: 0;
				    padding-left: 15px;
				}
			}
		}


		
		.about-content{
			.media-left, 
			.media-right, 
			.media-body{
				display: table-cell;
			}
			.icon-style{
				margin:auto;
			    margin-right: 5px;
			}
			br{
				display: block;
			}
		}

		// tab
		.tab-content {
			img{
			    min-width: auto;
			    margin: 0;
			    margin-right: 20px;
			    float: left !important;
			}
		}

		// call to action
		.call_to_action{
			padding-left: 0;
			padding-right: 0;
			padding-top: 37px;
			padding-bottom: 50px;
			br{
				display: inline-block;
			}
		}

		// testimonials
		.testimonials{
			h5{
				padding-top: 20px;
			}
			.media{
				text-align: left;
				padding-right: 16px;
			}
			.testimonials-body{
			    padding-bottom: 7px;
				p{
					padding-bottom: 12px;
				}
			}
			.testimonial-author{
				float: left;
				margin-right: 11px;
			}
		}

		// .Counter

		.Counter{
			.content{
				padding: 5px 12px 0;
			}
			.col-md-4{
				padding-left: 15px;
				padding-bottom: 4px;
			}
			.icon-style{
				float: left;
			}
			.Counter-last-child{
				.counter-box{
				    transform: translateY(0);
				}
			}
		}

		//out_mission
		.out_mission{
			.title-section{
			    padding-bottom: 16px;
		    }
		}

		/*=======
			newsletter			
		=========*/


		.counter-box{
			.icon-style{
				margin-right: 15px;
			}
		}

		/*=========
			* About page style
		=========== */
		// tab

			.out_mission {
				.mission-description{
					padding-bottom: 20px;
				}
				.content-left{
					padding-top: 0;
					.mission-description{
						padding: 0;
					}
				}
				.content-list{
					margin-bottom: 0;
				    padding: 10px 0;
					li{
						padding-top: 5px;
						padding-bottom: 5px;
					}
				}
			}

			.tab-content {
				p{
					margin: 0;
				}
			}

			.usefull-link{
				display: block;
			}


		/*=========
			* Instructors
		===========*/
		#Instructors,
		#latest_news{
			padding: 0;
		}
		.Instructors{
			padding: 50px 0 27px;
			.Instructors-last-child{
				margin-bottom: 8px;
			}
			.block-content {
				border-bottom: 2px solid;
				.content{
					bottom: -98px;
				}
			}
			.block-content:hover {
				.content{
				    height: 184px;
					border-bottom: 1px solid $brand-primary;
				}
				.content-text p{
					padding: 7px 0;
				}
			}
		}

		.instruction-sidbar-img{
			float: left;
			margin-right: 18px;
		}

		.Instructors-page{
			.block-content{
			    margin-bottom: 120px;
		    }
		}

		.president{
			img{
				width: 100%;
			}
			p{
				padding-bottom: 0;
				margin: 0;
			}
			.col-md-3,
			.col-md-9,
			.col-md-12{
				padding: 0;
			}
			.president-content{
				display: flex;
			}
			.president-title{
			    padding-left: 60px;
			    padding-right: 20px;
			    position: absolute;
			    left: 0;
			    top: 50%;
			    transform: translateY(-50%);
			}
		}

		/*=======
			single-courses page				
		=========*/

		.description{
			padding-top: 30px;
		}

    	.share_section{
    		h6{
    			padding-top: 0;
    			padding-bottom: 5px;
    			margin-right: 10px;
    		}
    		.media-section{
				padding:0;
				margin: 0;
				text-align: right;
    		}
    	}

    	.rex-forms {
    		.col-md-4, .col-md-6, .col-md-12{
    			padding: 0 6px;
    		}
    	}

		.instructor-img{
			float: left;
			margin-right: 8px;
		}

		.description h4,
		.course_detail h4{
			font-size: 22px;
		}

		.course_detail h2{
			font-size: 32px;
		}

		.learning-list ul{
		    margin-right: 19px;
		    margin-left: 0;
		}

		/*=======
			courses.html			
		=========*/
		
		.courses_page_menu{
		    text-align: right;
		    padding-bottom: 20px;
		}

		.courses_page{
		    .block-content{
		        .entry-meta{
		            text-align: left;
		        }
		    }
		}

		.list-group{
		    .description{
		        padding: 20px 0; 
		        display: none;
		    }
		}

		.courses_page_btn{
			margin-right: 15px;
			margin-bottom: 20px;
			padding: 15px 21px;
			i{
				margin-left: 50px;
			}
		}

		.courses_page-right{
		    float: right;
		}

		.list-group {
			.block-content{
				margin-bottom: 50px;
			}
		}



		/*=======
			blog-right		
		=========*/

		.blog-page {
			.blog-title {
				h3{
					font-size: 18px;
					line-height: 3;
				}
			}
			.description{
				padding-top: 3px;
			}
		}

		.blog-right{
			float: right;
		}

		.block-date{
			float: left;
			margin-right: 15px;
		}

		.entry-meta{
			text-align: left;
		}

		.rex-comments{
			
		}

		.read-more{
		    display: inline;
		    float: right;
		}

		/*=======
			Contact page		
		=========*/

		.contact-us .contact-from{
			padding-top: 50px;
		}
		.rex-map{
			padding-bottom: 0;
		}

		footer{
			text-align: left;
			p{
				text-align: left !important;
			}
			.align-right{
				text-align: right !important;
			}
		}

		.addres-icon{
			float: left;
			margin-right: 15px;
		}

		.single-blog{
			.blog-title{
				padding: 0 0 40px;
			}
		}

		.single-blog .description {
		    padding-top: 0;
		}

		.author-description{
			padding-bottom: 0;
			.media-left{
			    display: table-cell;
			    margin-bottom: 0;
			    padding-right: 10px;
		    }
		}

		.instructor-content {
			.instruction-sidbar-title{
			    padding-left: 8px;
		        padding-top: 6px;
			}
		}

		.popular_courses{
		    padding: 50px 0 55px;
		}

		/*=======
			partners page		
		=========*/
		.partners{
			.partners-logo{
				margin-bottom: 0;
			}
		}

		/*===========
			footer
		=============*/
		footer{
			h3{
				padding-bottom: 30px;
			}
			.footer-section{
				margin-top: 60px;
				border-color: transparent;
				&:first-child{
					margin-top: 60px;
				}
			}
			.phone-number{
				br{
					display: none;
				}
				a{
					margin: 0;
				}
			}
			.address{
				h6,p{
					display: block;
				}
			}
		}

		.footer-description {
			br{
				display: none;
			}
			p{
				padding: 30px 0;
			}
		}

		.faq-section{
			li{
				padding: 10px 0;
			}
		}

		.copyright{
			margin-top: 58px;
		}




    }

    /* Large Devices, Wide Screens */
    @media only screen and (min-width : 1200px) {
		
		section{
			padding: 120px 0 128px;
		}

		aside.widget{
			padding-left: 15px;
			padding-right: 15px;
			ul li{
				padding: 15px 18px 15px 35px;
			}
		}

		input{
			height: 60px !important;
		}
		textarea{
			height: 180px !important;
		}

		.title-section {
			h2{
				font-size: 32px;
			}
			p{
				font-size: 26px;
			}
		}

		.form-group{
			margin-bottom: 28px;
		}

		.gallery{
			img{
				min-width: 94px;
			}
		}

		.rex-overlay{
			top: 116px;
		}

		.Counter{
			padding: 80px 0;
		}


		.partners{
		    padding: 80px 0;
		}

		.padding-top{
			padding-top: 20px;
		}

		.author-description{
			padding-top: 37px;
		}

		.rex-tab {
			h2{
				font-size: 32px;
			}
		}

		.author,
		.categories-links{
		    padding-right: 14px;
		}

		aside.coures-reat{
		    padding: 0 15px;
		}

		.breadcum h4{
			font-size: 38px;
		}

		.search-btn{
			.rex-btn-text-icon{
				margin-top: 5px;
			}
		}

		.newsletter{
			padding: 0 0 80px;
		}


		// nav section

		ul.main-ul{
			padding-left: 30%;
			li {
				a{
					padding: 31px 30px
				}
			}
		}

		.overlay a{
		    top: 18px;
		}

		.menu-search .search-input input{
			width: 80%;
			float: right;
		}

		// end

		// silder

		.silder{
			h1,.slider-btn,.slider-text{
				text-align: left;
				padding-left: 20%;
			}
			h1{
				top: auto !important;
			    transform: translateY(-9px);
				// top: 100px !important;
			}
			.slider-text{
				top: auto !important;
			    transform: translateY(141%);
				// top: 107px !important;
			}
			.slider-btn{
			    top: auto !important;
		        transform: translateY(256%);
			    // top: 216px !important;
			}
			.slide-list{
				padding: 13% 0;
				position: relative !important;
			}
			.slide-center {
				h1,.slider-btn, .slider-text{
					text-align: center;
					padding-left: 0;
				}
				.slider-btn{
					top: auto !important;
				    transform: translateY(218%);
				}
			}

			.slider-last-child{
				h1{
				    transform: translateY(-9px);
				}
				.slider-text{
					transform: translateY(74%);
				}
				.slider-btn{
					transform: translateY(193%);
				}
			}
		}

		// end

		.search-full-width{
			input{
				font-size: 18px;
			}
			.search-btn{
				height: 60px;
				.rex-btn-text{
					padding: 0 25px;
				}
			}
		}

    	.block-content{
    		margin: 0;
    	}

		.Instructors{
			.content{
				padding: 30px 40px;
			}
			.block-content:hover {
				.content-text {
					p{
					    padding: 7px 0 12px;
					}
				}
			}
		}


		// .features 
		.features{
			padding: 68px 0 85px;
			p{
				padding: 0 20px 0;
				margin: 0;
			}
		}


		// about section
		.About_Us{
			padding: 0;
			.about-content h2{
				padding-left: 0;
			}
			img{
				float: right;
				padding-right: 20px;
			}
			.col-md-6{
				padding-left: 0;
			}
			.about-content{
				// padding: 99px 0 0;
				padding: 5% 0;
				h2{
					margin-top: 20px;
				    font-size: 32px;
				}
				p{
					padding-left: 0;
				}
			}
			.media{
				padding-bottom: 32px;
				padding-left: 0;
			}
		}

		.about-content{
			.media-left, 
			.media-right, 
			.media-body{
				padding-right: 10px;
			}
		}

		// popular_courses

		.popular_courses{
			padding: 120px 0 128px;
			.block-content{
				.author{
					padding-right: 20px;
					margin-right: 20px;
				}
			}
		}


		// testimonials
		.testimonials{
			.title-section{
			    padding-bottom: 33px;
			}
			h5{
				margin-bottom: 0;
			}
			.testimonial-author{
				margin: 0;
				float: left;
				margin-right: 25px;
			}
			.media{
				text-align: left;
			    padding: 0 22px 0 25px;
			}
			.testimonials-img{
				padding-top: 28px;
			}
			.testimonials-body{
				padding-left: 128px;
				position: relative;
				padding-bottom: 20px;
			}
			.quotation{
				position: absolute;
				top: 24px;
				left: 0;
			}
		}


		/*=======
			Instructors 
		=========*/

		.call_to_action{
			padding-top: 114px;
			padding-bottom: 127px;
			h1{
				font-size: 40px;
				line-height: 1.1;
			}
		}

		.testimonials{
		    padding-bottom: 95px;
		}

		/*=======
			* About page
		=========*/

		.out_mission {
			.content-left {
				.mission-description{
					padding-bottom: 10px;
					margin-bottom: 0;
				}
			}
			.content-list {
				li{
					padding-top: 8px;
					padding-bottom: 8px;
				}
			}
		}

		/*=========
			* Instructors
		===========*/

		.out_mission{
			.title-section{
				padding-bottom: 25px;
				p{
					&:before{
						display: none;
					}
				}
			}
			.content-list{
			    margin-bottom: 15px;
			}
		}


		/*=========
			* Instructors
		===========*/

		.Instructors{
		    padding: 120px 0 223px;
			.Instructors-last-child{
				margin-bottom: 0;
			}
		}

		.Instructors-page{
			padding: 120px 0 95px;
		}

		.Instructors-page-slider{
			padding: 120px 0 0;
		}

		.president{
			padding-bottom: 0;
		}

		.single-instructor{
			.sidebar-title-section{
				h2{
					font-size: 25px;
				}
			}
		}


		/*=======
			single-courses page				
		=========*/


		.course_detail {
			h2{
				font-size: 32px;
			    margin-top: 18px;
			}
		}

		.course-reating{
			.rex-bottom-medium{
				display: block;
				.btn-text{
					float: right;
					width: 100px;
				}
			}
		}

		.single-img{
			margin-bottom: 40px;
		}

		.learning-list{
			padding-bottom: 30px;
			ul{
				display: inline-block;
				margin-right: 100px;
				margin-left: 0;
				&:last-child{
					margin-right: 0;
				}
				li{
				    padding: 10px 0 0px 20px;
				}
			}
		}

		.review{
			padding-top: 60px;
			h4{
				padding-bottom: 25px;
			}
		}

		.review-form{
			padding: 30px 0 0;
			.rex-bottom-medium{
				margin-top: 20px;
				margin-bottom: 0;
			    transform: translateX(5px);
			}
		}

		.rex-forms{
		    transform: translateX(-5px);
		}

		/*=======
			courses.html			
		=========*/

		.courses_page{
			padding: 80px 0 128px;
		}

		.sorting-btn{
		    display: inline-block;
	        transform: translateY(-8px);
		}

		.list-group-item{
			float: left !important;
		}

		.list-group{
			.description{
				display: none;
			}
		}

		.courses-list{
			.description{
				display: block;
			}
		}

		.list-group-item{
			.description{
				display: block;
			}
		}

		.courses_page{
			.col-md-3{
				padding-left: 0;
			}
			.block-content h6{
				font-size: inherit;
			}
			.list-group-item{
				.block-content .img-hover{
					width: 500px;
				}
			}
		}

		.courses_page_menu{
			padding-right: 0;
		}

		.course_detail{
			.description{
				padding-top: 0;
			}
		}

		.single-course{
		    padding-top: 110px;
		}

		.instructors-media-link{
			display: inline-block;
		}

		.courses-slider{
			.owl-controls{
				top: 35%;
				width: 100%;
			}
		}


		/*=======
			blog.html			
		=========*/

		.blog-page{
			.blog-title{
				h3{
					margin: 0;
					font-size: 26px;
					line-height: 2.3;
				}
			}
			.blog-content{
				padding: 40px 40px 20px;
			}
		}

		/*=======
			Single-blog page		
		=========*/
		.single-blog{
			.description{
				padding-top: 0;
			}
		}



		/*=======
			Contact page		
		=========*/

		footer {
			.usefull-link {
				li{
					max-width: 100%;
					display: block;
					padding: 6px 0px;
				}
			}
		}






	}

/*=====  End of Responsive style block  ======*/

