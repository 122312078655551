
.clearfix{
	clear: both;
}

// Icon radius style
.icon-style{
	width: 68px;
	height: 68px;
	background: $brand-primary;
	border-radius: 100%;
	position: relative;
	border: 1px solid $gray-lignt;
	transition: 0.5s;
	i{
		font-size: 24px;
		color: $icon-color;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: 0.3s;
	}
}

//  icon-style

.features-list{
	position: relative;
	i,h1,h2,h3,h4,h5,h6{
		transition: 0.5s;
	}
	&:before{
		left: 0;
		bottom: 0;
		height: 0;
		width: 100%;
		content: "";
		position: absolute;
		transition: 0.5s;
		background: $table-bg-accent;
	}
	&:after{
		content: "";
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		position: absolute;
		background: $gray-lignt;
	}
	
	&:hover{
		&:before{
			height: 100%;
		}
		i,
		h1,h2,h3,h4,h5,h6{
			color: $brand-primary;
		}
	}
}

// instructors-media-link

.instructors-media-link{
	padding-top: 8px;
}

.instructors-media-link{
	i{
		margin: 0 !important;
		color: #fff !important;
	}
	a{
	    width: 25px;
	    height: 25px;
	    background: red;
        margin-right: 4px;
		text-align: center;
	    border-radius: 50%;
	    display: inline-block;
	}
    a[href="https://www.facebook.com/"]{
    	background: #3b5998;
    }
    a[href="https://twitter.com/"]{
    	background: #55acee;
    }
    a[href="https://www.linkedin.com/"]{
    	background: #0077b5;
    }
    a[href="https://plus.google.com/"]{
    	background: #dc4e41;
    }

}

.display-none{
	display: none;
}

// Modal Contents
.modal-content{
	text-align: center;
    border-radius: 0;
	.form-group input{
		// height: auto !important;
		color: $brand-primary;
	}
	.rex-bottom-medium{
		color: $white-color;
	}
	.modal-body{
	    padding: 0 15px;
	}
	.modal-header,
	.modal-footer{
		text-align: center;
		border-color: transparent;
	}
	.modal-header{
		padding: 40px 15px 15px 15px;
	}
	.modal-footer{
	    padding: 15px 15px 40px 15px;
	}
}

.modal-open {
	.modal{
	    background: rgba(0, 0, 0, 0.9);
    }
}

.modal-body{
	form{
		.form-group{
			margin-bottom: 10px;
		}
	}
}

.modal-dialog{
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    min-width: 100%;
    transform: translate(-50%, -50%) !important;
}

.modal-header .close{
    margin-top: -30px;
	&:focus{
	    outline: none;
	}
}

// active

.active{
	color: $brand-primary !important;
}

a#grid,
a#list{
	background: transparent !important;
	box-shadow: none !important;
}
a#grid:hover,
a#grid:focus{
	border-color: transparent;
}

// center title

.center-title-section{
	text-align: center;
    padding-bottom: 39px !important;
	p{
		&:before{
			display: none;
		}
	}
}

//  title-section

.title-section{
	h1,h2,h3,h4,h5,h6{
		text-transform: uppercase;
		margin-top: 0;
	}
	h2{
		font-size: 20px;
	}
	p{
		// font-size: 22px;
  //       line-height: 36px;
		font-size: 18px;
        line-height: 25px;
	    padding-top: 0;
	    padding-bottom: 10px;
	    margin: 0;
	    position: relative;
	    color: $text-color;
	    font-weight: 400;
	    &:before{
	    	content: "";
	    	position: absolute;
	    	left: 0;
	    	bottom: 0;
	    	width: 100%;
	    	height: 1px;
	    	background: $gray-lignt;
	    }
	}
}

// owl-buttons

.owl-controls{
	text-align: center;
}

.owl-buttons{
	.owl-prev,
	.owl-next{
		width: 40px;
		height: 40px;
		display: inline-block;
		background: $white-color;
		border: 1px solid $gray-lignt;
		position: relative;
		border-radius: 4px;
		transition: 0.5s;
		&:hover{
			background: $brand-primary;
			i{
				color: #fff;
			}
		}
	}
	.owl-prev{
		margin-right: 5px;
	}
	i{
		top: 50%;
		left: 50%;
		font-size: 18px;
		position: absolute;
		transform: translate(-50%, -50%);
	}
}



.block-content{
	background: $gray-bg;
	border-bottom: 1px solid $gray-lignt;
	margin-bottom: 20px;
	transition: 0.5s;
	&:hover{
		background: $white-color;
	}
	img{
		width: 100%;
	}
	h6{
		transition: 0.5s;
	}
	.img-hover{
		overflow: hidden;
		img{
		    transition: all 0.5s ease;
		}
	}
	&:hover{
		.block-date{
			p{
				color: $white-color;
			}
			&:before{
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				margin: 0;
				opacity: 1;
				border-radius: 0;
			}
		}
	}
}

.courses-img{
	position: relative;
	&:before{
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		background: rgba(0, 0, 0,0.8);
		transition: 0.5s;
	}
}

.courses-overlay{
	position: absolute;
	top: 50%;
	left: 50%;
	opacity: 0;
	transition: 0.5s;
	border-radius: 100%;
	width: 70px;
	height: 70px;
	text-align: center;
	transform: translate(-50%, 50%);
	background: $brand-primary;
	h6{
		color: $white-color;		
		font-size: 16px;
		line-height: 52px !important;
	}
}

.content,
.block-title,
.entry-meta,
.author,
.categories-links, 
.rex-comments,
.rex-comments,
.block-date{
	h6{
		text-transform: uppercase;
		margin: 0;
	}
	a, i{
		color: $brand-primary;
	}
	i{
		margin-right: 8px;
	}
}

.content{
	// padding: 40px 40px 0;
	padding: 20px 20px 5px;
	position: relative;
	a{
		&:hover{
			h6{
				color: $brand-primary;
			}
		}
	}
}
.block-title{
	// padding: 6% 0;
	margin: 0;
}

.author{
	
	p{
		margin: 0;
	}
	a{
		transition: 0.5s;
		&:hover{
			color: $secondary-color !important;
		}
	}
}
.categories-links{
	a{
		transition: 0.5s;
		&:hover{
			color: $secondary-color !important;
		}
	}
}

.categories-links, .author, .rex-comments, .read-more{
	font-weight: 500;	
}

.categories-links, .author, .rex-comments{
	display: inline-block;
	margin: 0 5px 0 0;
}

.rex-comments{
	// float: right;
	p{
		margin: 0;
	}
}

.block-date{
	display: inline-block;
	background: $white-color;
	text-align: center;
	padding: 7px 10px;
	border: 1px solid $gray-lignt;
	border-radius: 4px;
	// float: left;
	// margin-right: 8px;
	position: relative;
	&:before{
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 10px;
		height: 10px;
		background: $brand-primary;
		// border-radius: 100%;
		margin-top: -5px;
		margin-left: -5px;
		transition: 0.4s;
		opacity: 0;
	}
	p{
		margin: 0;
		text-transform: uppercase;
		font-family: $font-family-sans-serif-montserrat;
		font-weight: 700;
		line-height: 18px;
		position: relative;
	}
}

.block-title{
	padding: 10px 0;
}

.title-section{
	padding-bottom: 30px;
}


.newsletter-input{
	.input-group{
		width: 100%;
	}
	input.form-control{
		background: none;
		border-radius: 4px !important;
		color: $white-color;
		height: 50px !important;
	}
	button{
		padding: 14px;
		transition: 0.5s;
	}
	i{
		font-size: 18px;
	}
}

// pages

.pages{
	text-align: center;
	a,span{
		display: inline-block;
	}
}

// Tabe

.rex-tab{
	h2{
		margin-top: 0;
		font-size: 20px;
		padding-bottom: 20px;
		text-transform: uppercase;
	}
}
.nav-tabs{
	border: transparent;
	li{
		margin-right: 5px;
		width: 100%;
		text-align: center;
		background: $gray-bg;
		border-top: 3px solid $gray-lignt;
		&:last-child{
			margin-right: 0;
		}
	}
	a{
		background: $gray-bg !important;
		border: transparent !important;
	}
	li > a{
		padding: 13px;
	}
	.active{
		border-color: $brand-primary;
		position: relative;
		&:before{
			content: "";
			position: absolute;
			left: 50%;
			top: 0px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 6px 6px 0 6px;
			border-color: #01B3EE transparent transparent transparent;
			z-index: 10;
			transform: translateX(-50%);
		}
	}
}

.tab-content{
	padding: 15px 0;
	img{
		float: none !important;
		min-width: 100%;
		display: block;
		height: auto;
		margin-bottom: 20px;
	}
	p{
		color: $text-color;
	}
}

// accordion

.rex-accordion{
	i{
		display: none;
	}
	.rx-panel-title{

	}
	.panel,
	.panel-heading{
		border-radius: 0;
	}
	.panel{
		margin-top: 0 !important;
		border-top-color: transparent;
		&:first-child{
			border-top-color: $gray-lignt;
		}
	}
	.panel-heading{
		border-color: $gray-lignt;
		cursor: pointer;
		background: $white-color;
		transition: 0.5s;
		&:hover{
			background: $brand-primary;
			h5, i{
				color: $white-color;
			}
		}
	}
	.collapsed{
		background: $gray-bg;
		color: $text-color;
	}
	.panel-body{
		// border-top-color: transparent !important;
	}
}

// media-link style

.media-section{
	h6{
		margin-bottom: 5px;
	}
	h6, .media-link{
		display: inline-block;
	}
}

.media-link{
	img{
		filter: grayscale(0);
		transition: 0.5s;
		margin: 0 2px;
		&:hover{
			filter: grayscale(1);
		}
	}
}

.gallery-img{
	a{
		margin-right: 3px;
	}
}


// comments

.comments{
	background: $dark-light;
	padding: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid $gray-lignt;
	h6{
		text-transform: uppercase;
		margin: 0;
	}
	span{
		display: block;
		font-weight: 600;
	}
	p{
		margin: 0;
		padding-top: 8px;
	}
	br{
		display: none;
	}
	.comments-content{
		padding: 10px 0 0;		 
	}
}

.rex-forms{
	.col-md-4,
	.col-md-6,
	.col-md-12{
		padding: 0;
	}
	input, textarea{
		border-radius: 0;
		resize: none;
		border: 0;
		border-bottom: 1px solid $gray-lignt;
		background: $dark-light;
		box-shadow: none;
		padding: 20px;
		&:focus{
			box-shadow: none;
		}
	}

}


// usefull-link

.usefull-link{
	text-align: left;
	li{
		padding: 11px 0;
		display: inline-block;
		min-width: 45%;
	    // border-bottom: 1px solid #363f44;
		&:first-child{
			padding-top: 0;
		}
		&:last-child{
			padding-bottom: 0;
			border-bottom: transparent;
		}
		a{
			color: $text-color-footer;
			position: relative;
			padding-left: 18px;
			&:before{
				content: "";
				position: absolute;
				top: 50%;
				left: 0;
				width: 	8px;
				height: 8px;
				border-radius: 100%;
				background: #515f67;
				transform: translate(-0%, -50%);
			}
		}
	}
}

.quote{
	padding: 30px;
    margin-top: 26px;
	background: $gray-bg;
    margin-bottom: 30px;
    position: relative;
    &:before{
    	content: "";
    	position: absolute;
    	left: 0;
    	top: 0;
    	width: 	3px;
    	height: 100%;
    	background: $brand-primary;
    }
	p{
		margin: 0;
		font-size: 18px;
		font-weight: 600;
		line-height: 30px;
	}
}

.tags{
	a{
		color: $brand-primary;
	}
	.tags-icon{
		display: inline-block;
	    margin-right: 12px;
	}
}

.instructor-info{
	float: right !important;
	color: $brand-primary !important;
}

.instructor-profile{
	width: 100%;
	padding: 28px 0;
	text-align: center;
	background: $dark-light;
	border-bottom: 1px solid $gray-lignt;
	a{
	    width: 35px;
	    height: 35px;
        padding: 6px 0;
	    font-size: 17px;
	}
}


.author-description{
    padding: 20px 0;
	h4{
		text-transform: uppercase;
		padding-bottom: 10px;
	}
	.media-left{
		display: inline-block;
		margin-bottom: 15px;
	    padding-right: 0;
	}
	.media-body{
		// padding-left: 23px;
		padding-left: 0;
		padding-top: 20px;
	    display: block;
	    width: auto;
	}
}

// blog-right page

.blog-right{
	float: none;
}

.pagination{
    display: inline-block;
	li{
		a{
			padding: 10px 20px;
			color: $light-dark;
			&:hover{
				background: $brand-primary;
				border-color: $brand-primary;
				color: #fff !important;
			}
		}
	}
	.active{
		a:hover{
			color: #fff !important;
		}
	}
}

.pagination > li:first-child > a, 
.pagination > li:first-child > span,
.pagination > li:last-child > a, 
.pagination > li:last-child > span{
	border-radius: 0;
}

.pagination > .active > a, 
.pagination > .active > a:hover, 
.pagination > .active > a:focus, 
.pagination > .active > span, 
.pagination > .active > span:hover, 
.pagination > .active > span:focus{
	background: $brand-primary;
	border-color: $brand-primary;
}

.blog-pagination{
	overflow: hidden;
    display: block;
    padding-bottom: 50px;
    .pagination{
    	margin: 0;
    	display: inline-block;
    }
}


/* featured section */
.featured-section{
	.instructor-content{
		padding: 20px;
	}
}

/* REVIEWS Section */

.rating,{
	padding: 5px;
	i{
		color: $brand-primary;
		font-size: 12px;
	}
}

.review-form {
	p{
		margin: 0;
	}
}

.select-rating{
	padding-bottom: 15px;
	i{
		color: $brand-primary;
	}
}

.reply{
	font-weight: 500;
	i{
		margin-left: 4px;
	}
	p{
		padding: 0;
	}
}

.instragram{
	a{
		display: inline-block;
		width: 80px;
		height: 53px;
		margin: 4px 1px 0px -1px;
	}
	img{
		width: 100%;
	}
}

.single-img{
	img{
		width: 100%;
	}
}

.courses_page_menu{
	button{
		border-color: $gray-lignt;
	}
}

//  slider section
.silder{
	.rex-slider{
		.inner{
			.button-slider{
				font-size: 18px !important;
				border: 1px solid $white-color !important;
				background: transparent !important;
				border-radius: 4px !important;
				transition: 0.5s !important;
				opacity: 1 !important;
			    text-indent: -9999px;
			    &:hover{
			    	background: $brand-primary !important;
			    	border-color: transparent !important;
			    }
			}
			.button-previous,
			.button-next{
				position: relative;
				&:before{
					position: absolute;
					left: 50%;
					top: 50%;
					color: $white-color;
					text-indent: 0;
					font-family: FontAwesome;
					transform: translate(-50%, -55%);
				}
			}
			.button-previous{
				&:before{
					content: "\f104";
				}
			}
			.button-next{
				&:before{
					content: "\f105";
				}
			}
		}
	}
}

#scrollUp {
    bottom: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: #555;
    color: #fff;
}


// search section

.search-full-width{
	position: relative;
	input{
		height: 50px;
		font-size: 12px;
	}
	.search-btn{
		position: absolute;
		top: 0;
		right: 14px;
		height: 100%;
	    border-top-left-radius: 0;
	    border-bottom-left-radius: 0;
		&:hover{
			background: #42B1D6;
		}
	}
	.search-btn .rex-btn-text{
		padding: 0 15px;
	}
	.search-btn .rex-btn-text-icon{
		margin-top: 5px;
	}
}

.modal-open .modal{
    background: rgba(0, 0, 0, 0);
    margin: 15px;
}


/* Preloder style */

.wrapper {
		padding-left: 1em;
		padding-right: 1em;
	margin: auto;
	display: block;
	width: 49px;
}

.cssload-loader {
		width: 49px;
		height: 49px;
		border-radius: 50%;
		// margin: 3em;
		display: inline-block;
		position: relative;
		vertical-align: middle;
}

.cssload-loader {
		width: 49px;
		height: 49px;
		border-radius: 50%;
		// margin: 3em;
		display: inline-block;
		position: relative;
		vertical-align: middle;
}
.cssload-loader,
.cssload-loader:before,
.cssload-loader:after {
		animation: 1.15s infinite ease-in-out;
		-o-animation: 1.15s infinite ease-in-out;
		-ms-animation: 1.15s infinite ease-in-out;
		-webkit-animation: 1.15s infinite ease-in-out;
		-moz-animation: 1.15s infinite ease-in-out;
}
.cssload-loader:before,
.cssload-loader:after {
		width: 100%; 
		height: 100%;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: 0;
}

.cssload-loader:before,
.cssload-loader:after {
		content: "";
}
.cssload-loader:before {
		border: 1px solid rgb(13,179,235);
		top: -1px;
		left: -1px;
		opacity: 0;
		animation-name: cssload-pulse;
		-o-animation-name: cssload-pulse;
		-ms-animation-name: cssload-pulse;
		-webkit-animation-name: cssload-pulse;
		-moz-animation-name: cssload-pulse;
}



.cssload-loader:after {
		background-color: rgba(13,179,235,0.66);
		animation-name: cssload-pulse-2;
		-o-animation-name: cssload-pulse-2;
		-ms-animation-name: cssload-pulse-2;
		-webkit-animation-name: cssload-pulse-2;
		-moz-animation-name: cssload-pulse-2;
}



@keyframes cssload-pulse {
	0% { transform: scale(1); opacity: 1; }
	50% { transform: scale(1.3); opacity: 0; }
	100% { transform: scale(1.3); opacity: 0; }
}

@-o-keyframes cssload-pulse {
	0% { -o-transform: scale(1); opacity: 1; }
	50% { -o-transform: scale(1.3); opacity: 0; }
	100% { -o-transform: scale(1.3); opacity: 0; }
}

@-ms-keyframes cssload-pulse {
	0% { -ms-transform: scale(1); opacity: 1; }
	50% { -ms-transform: scale(1.3); opacity: 0; }
	100% { -ms-transform: scale(1.3); opacity: 0; }
}

@-webkit-keyframes cssload-pulse {
	0% { -webkit-transform: scale(1); opacity: 1; }
	50% { -webkit-transform: scale(1.3); opacity: 0; }
	100% { -webkit-transform: scale(1.3); opacity: 0; }
}

@-moz-keyframes cssload-pulse {
	0% { -moz-transform: scale(1); opacity: 1; }
	50% { -moz-transform: scale(1.3); opacity: 0; }
	100% { -moz-transform: scale(1.3); opacity: 0; }
}

@keyframes cssload-pulse-2 {
	0% { transform: scale(1); }
	50% { transform: scale(0.7); }
	100% { transform: scale(1); }
}

@-o-keyframes cssload-pulse-2 {
	0% { -o-transform: scale(1); }
	50% { -o-transform: scale(0.7); }
	100% { -o-transform: scale(1); }
}

@-ms-keyframes cssload-pulse-2 {
	0% { -ms-transform: scale(1); }
	50% { -ms-transform: scale(0.7); }
	100% { -ms-transform: scale(1); }
}

@-webkit-keyframes cssload-pulse-2 {
	0% { -webkit-transform: scale(1); }
	50% { -webkit-transform: scale(0.7); }
	100% { -webkit-transform: scale(1); }
}

@-moz-keyframes cssload-pulse-2 {
	0% { -moz-transform: scale(1); }
	50% { -moz-transform: scale(0.7); }
	100% { -moz-transform: scale(1); }
}

/* Animated SVG Loader Style
   ========================= */
div.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  z-index: 9999999999999;
}
div.loading .wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* hide scroolUp when Loading */
.loading #rex-scrollUp {
  display: none !important;
}
