
	.popular_courses{
		.block-content{
			&:hover{
				transform: translateY(0);
				border-color: #01B3EE;
				.courses-overlay{
					opacity: 1;
					transform: translate(-50%, -50%);
				}
				.courses-img:before{
					opacity: 1;
				}
			}
			.author{
				padding-right: 5px;
				margin-right: 5px;
			}
			.date{
				padding-right: 0px;
				margin-right: 0px;
			}
			.entry-meta{
				padding-top: 6px;
				margin-top: 6px;
			}
		}
		.owl-controls{
			right: 15px;
		}
	}

	.popular_courses{
		.title-section{
			padding-left: 30px;
			padding-right: 30px;
		}
	}
