

	.Instructors-page{
		.block-content{
			margin-bottom: 50px;
		}
		.block-content{
			.content{
				margin-top: 0;
				max-height: none;
			}
			.content-text{
				// opacity: 1;
				transform: translateY(0);
			}
			&:hover{
			    // min-height: 560px;
			}
		}
		nav{
			ul{
				margin-bottom: 0;
			}
		}
	}

	.president{

		.president-content{
			background: $gray-bg;
			// display: flex;
			img{
				margin: 0 auto;
			}
		}

		.content-text{
			border-bottom: 1px solid $gray-lignt;					
		}

		.president-title{
			padding-top: 20px;
			h3{
				text-transform: uppercase;
				margin: 0;
				padding-bottom: 5px;
			}
			span{
				color: $brand-primary;
				text-transform: uppercase;
				font-family: $font-family-sans-serif-montserrat;
				font-weight: 700;
			}
		}
		p{
			padding: 20px 0;
		}
	}

	.Instructors-page{

	}