

	.testimonials{
		background: url("../img/lined_paper.png");
		border-top: 1px solid $gray-lignt;
		border-bottom: 1px solid $gray-lignt;
		.testimonial-author{
			margin: 0 auto 20px;
		    width: 96px;
		    height: 96px;
		    border-radius: 100%;
		}
		h5{
			color: $text-color;
		}
		span{
			color: $brand-primary;
		}
		.media{
			text-align: center;
			padding-top: 20px;
		}
		.testimonials-img{
			display: block;
		}
		.testimonials-body{
			padding-bottom: 20px;
			p{
				padding: 20px 10px;
			}
		}
		.owl-carousel{
			background: #fff;
		    box-shadow: 0px 2px 6px rgba(232, 232, 232, 0.9);
		}
		.owl-buttons{
		    padding-bottom: 15px;
		}
	}