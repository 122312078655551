/**
 * ---------------------------------------------------------------------------
 * 0?. Base Styles.
 * ---------------------------------------------------------------------------
 */

/* heading font style */

body{
	line-height: 24px !important;
	font-weight: 500;
	font-family: $font-family-base;
	color: $text-color;
}

ul{
	margin: 0;
	padding: 0;
	li{
		list-style: none;
	}
}
a{
	text-decoration: none !important;
	transition: 0.5s;
	&:hover{
		color: $brand-primary !important;
	}
}


section{
	padding: 50px 0;
	text-align: center;
}

h1,h2,h3,h4,h5,h6{
	font-weight: $headings-font-weight;
	font-family: $font-family-base-montserrat;
	color: $secondary-color;
}

h1{
	font-size: $font-size-h1;
}

h2{
	font-size: $font-size-h2;
}

h3{
	font-size: $font-size-h3;
}

h4{
	font-size: $font-size-h4;
}

h5{
	font-size: $font-size-h5;
}


h6{
	font-size: $font-size-h6;
}

.btn-primary,.label-primary{
	background: $brand-primary;
	&:hover{
		background: #00A4DA;
	}
}



.section-padding{
	padding: 100px 0;
}
.withoutPadding-leftRight{
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.aling-center{
	text-align: center;
}

.align-left{
	text-align: left;
}

.align-right{
	text-align: right;
}
