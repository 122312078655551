

	.course_detail{
		h2{
			font-size: 20px;
		}
		h4{
			font-size: 18px;
		}
		.rex-bottom-medium{
			margin-bottom: 20px;
		}
		.share_section{
			.media-link{
				padding-bottom: 0;
				margin: 0;
			}
		}
	}
	.course-reating{
		.rex-bottom-medium{
			&:hover{
				background: #00A4DA;
			}
			&:focus{
				color: $white-color;
				outline: none;
			}
		}
	}
	.sidebar-title-section{
		h2{
			text-transform: uppercase;
			margin-bottom: 20px;
			padding-bottom: 20px;
			border-bottom: 1px solid $gray-lignt;
		}
	}

	.description{
		padding-bottom: 20px;
		h4{
			margin: 0;
			text-transform: uppercase;
			padding: 15px 0;
		}
	}

	.learning-list{
		h4{
			text-transform: uppercase;
		}
		ul{
			margin-left: 20px;
			display: inline-block;
			text-align: left;
			li{
				padding:15px 0 7px 20px;
				position: relative;
				&:before{
					content: "";
					position: absolute;
					left: 0;
					top: 50%;
					width: 10px;
					height: 10px;
					background: #797979;
					border-radius: 100%;
					transform: translate(-50%);
				}
				p{
					margin: 0;
				}
			}
		}
	}

	.share_section{
		padding-top: 20px;
		margin-top: 20px;
		border-top: 1px solid $gray-lignt;
		h6{
			// padding: 20px 0;
			margin: 0;
		}
		.media-link{
			padding-bottom: 20px;
		}
	}

	.media-section{
		margin: 10px 0 20px;
	}

	.review{
		h4{
			text-transform: uppercase;
			padding-bottom: 20px;
		}
	}

	.review-form-title{
		padding-bottom: 6px;
		h4{
			text-transform: uppercase;
		    padding-left: 6px;
		}
	}

	.review-form{
		padding: 20px 0 50px;
	}

	.coures-reat{
		a{
			&:before{
				display: none;
			}
		}
		i{
		    transform: translateX(-20px);
		}
	}
	aside.coures-reat{
		padding: 0;
	}

	.sidbar-section{
		padding-bottom: 20px;
		h5{
			margin-bottom: 20px !important;
		}
		h6{
			padding: 0 !important;
			margin: 0 !important;
			&:before{
				display: none;
			}
		}
		img{
			margin-bottom: 0;
			float: left;
			margin-right: 20px;
		}
		ul{
			li{
				padding: 0 !important;
				border-bottom: transparent !important;
				a{
					&:before{
						display: none;
					}
				}
				img{
					margin-bottom: 0;
					float: none;
					margin-right: 0;
				}
			}
		}
	}

	.featured{
		.col-md-12{
			padding: 0;
		}
		h6{
			margin-bottom: 20px !important;
		}
		.featured-img{
			margin-right: 20px;
			float: left;
			img{
				margin-bottom: 0;
			}
		}
		.featured-content{
			h6{
				padding: 0;
				margin: 0 !important;
				&:before{
					display: none;
				}
			}
		}
		.featured-section{
			padding-bottom: 5px;
			margin-bottom: 15px;
			border-bottom: 1px solid $gray-lignt;
			&:last-child{
				border-bottom: transparent;
			}
		}
	}

	.instructor-img{
		display: inline-block;
		img{
			margin-bottom: 0;
		}
	}

	.instruction-sidbar-img{
		img{
			margin: 0 auto 5px;
		}
	}

	.instructor-content{
		border-bottom: 1px solid $gray-lignt;
		padding-bottom: 10px;
		.instruction-sidbar-title{
			h6{
				padding: 0;
				margin: 0;
				transition: 0.5s;
				&:before{
					display: none;
				}
			}
			.media-link{
				transform: translateX(-5px);
			}
		}
		.date{
			color: #8a8a8a;
		}
		a{
			color: $brand-primary;
			font-weight: 600;
			&:hover{
				h6{
					color: $brand-primary;
				}
			}
		}
	}

	.instructor{
		border-bottom: 0;
		padding-bottom: 0;
		padding-top: 10px;
	}

	.featured-section{
		.instructor-content{
			&:last-child{
				border-bottom: 0;
				margin: 0;
			}
		}
	}
