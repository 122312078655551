
	.latest_news{
		.title-section{
			padding: 0 15px 40px;
			text-align: center;
			p:before{
				display: none;
			}
		}
		.block-title{
		    padding-top: 8px;
		}

		.latest_news-last-child{
			margin-bottom: 6px;
		}
	}