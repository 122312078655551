

	.About_Us{
		background: url("../img/lined_paper.png");
		border-top: 1px solid $gray-lignt;
		border-bottom: 1px solid $gray-lignt;
		h2{
			text-transform: uppercase;
			font-size: 20px;
		}
		br{
			display: none;
		}
		h5{
			margin:0;
			color: $text-color;
			text-transform: uppercase;
		}
		p{
			// padding: 20px 15px;
			padding: 0 15px 20px 15px;
		    margin-bottom: 2%;
		}
		.media{
			padding: 0 15px 20px;
			p{
				padding: 4px 0 0;
			}
		}
		.col-md-12{
			padding: 0;
		}
		.icon-style{
			background: $white-color;
		}

		.rex-bottom-medium{
			margin-top: 20px;
		}
	}

	.hover-effect{
		&:hover{
			.icon-style{
				background: $brand-primary;
				i{
					color: $white-color;
				}
			}
		}
	}

	.about-content{
		.media-left, 
		.media-right, 
		.media-body{
			display: inline;
			padding-right: 0;
		}
		.icon-style{
			margin: 0 auto 10px;
		}
		br{
			// display: none;
		}
	}


	.about-content{
	    padding-top: 23px;
	}
