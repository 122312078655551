
.widget{
	margin-bottom: 20px;
	img{
		max-width: 100%;
		margin-bottom: 20px;
	    display: block;
	    height: auto;
	}
}

// archive style

aside.widget{
	background: $dark-light;
	padding: 15px 11px;
	margin-bottom: 30px;
	border-bottom: 1px solid $gray-lignt;
	h1,h2,h3,h4,h5,h6{
		text-transform: uppercase;
		padding-bottom: 10px;
		padding-left: 15px;
		margin-bottom: 10px;
		position: relative;
		&:before{
			content: "";
			position: absolute;
			bottom: 0;
			// left: 15px;
			left: 50%;
			width: 30px;
			height: 1px;
			background: $secondary-color;
			transform: translateX(-50%);
		}
	}
	ul{
		margin-bottom: 0;
		li{	
			padding: 10px 18px 10px 35px;
			// text-align: right;
			border-bottom: 1px solid $gray-lignt;
			color: $brand-primary;
			&:last-child{
				border-bottom: 0;
			}
			a{
				// float: left;
				display: block;
				color: $text-color;
				position: relative;
				&:before{
					content: "";
					position: absolute;
					left: -20px;
					top: 50%;
					width: 9px;
					height: 9px;
					border-radius: 100%;
					background: $gray-lignt;
					transform: translateY(-50%);
				}
			}
			ul{
				li{
					padding-left: 10px;
				}
			}
		}
	}
	select{
		width: 100%;
		padding: 10px;
	}
}

.widget.widget_categories,
.widget.widget_archive{
	ul{
		li{
			text-align: right;
			a{
				float: left;
			}
		}
	}
}

.widget.widget_nav_menu{
	ul{
		li{
			border: transparent;
			a{
				border-bottom: 1px solid $gray-lignt;
			}
		}
	}
}

.widget_calendar{
	table{
		width: 100%;
		text-align: center;
		th{
			text-align: center;
		}
		tfoot{
			text-align: left;
		}
	}
}

aside.widget.widget_archive{
	padding-bottom: 0;
	ul{
		li{
			&:last-child{
				border: transparent;
			}
		}
	}
}

.search{
	padding-top: 18px;
	input{
		height: 40px !important;
		border-radius: 0;
		border-color: transparent;
		box-shadow: none;
		&:focus{
			outline: none;
			box-shadow: none;
			border-color: transparent;
		}
	}
	i{
		transition: 0.5s;
	}
	button{
		display: block;
		border-color: transparent;
		float: right;
		&:focus, &:active{
			background: none !important;
			box-shadow: none !important;
			outline: none !important;
			border-color: transparent !important;
		}
		&:hover{
			background: transparent;
			border-color: transparent;
			i{
				color: $brand-primary;
			}
		}
	}
	.input-group-addon{
		padding: 0 !important;
		background: $white-color;
		border-color: transparent;
	}
	.form-group, .input-group{
		width: 100%;
	}
}

.gallery{
	a{
		display: inline-block;
		margin-right: 3px;
	}
	img{
		margin-bottom: 0;
		// width: 94px;
	}

}