/*All Bottom cstyle css*/

.rex-bottom-small,.rex-bottom-medium,.rex-bottom-large,.rex-bottom-x-large{
	display: inline-block;
    position: relative;
    outline: 0;
    font-style: normal;
    font-weight: 700;
    padding: 0 35px;
    background-color: $brand-primary;
    border: none;
    color: $white-color;
    text-transform: uppercase;
    border-radius: 4px;
    box-sizing: initial;
    &:hover {
	    color: $white-color!important;
	}
	.rex-btn-text{
		display: inline-block;
	    margin-right: 0;
	    vertical-align: middle;
	    float: left;
	    line-height: inherit;
	    &:hover{
			.rex-btn-text-icon {
				&:before{
					left: 0;
				}
		    }		
		}
	}
}

.rex-bottom-small,.rex-bottom-medium,.rex-bottom-large,.rex-bottom-x-large.rex-btn-icon{
	padding: 0;
    vertical-align: middle;
	.rex-btn-text-icon {
	    display: inline-block;
	    text-align: center;
	    font-size: 23px;
	    float: left;
	    line-height: inherit;
	    border-radius: 0 3px 3px 0;
	    position: relative;
	    overflow: hidden;
	    border-left: 1px solid #1bc1f5;
	    &:before {
		    display: inline-block;
		    width: 100%;
		    height: 100%;
		    position: absolute;
		    left: -100%;
		    top: 0;
		    content: "\f18e";
		    font-size: 18px;
		    font-family: FontAwesome;
		    overflow: hidden;
		    font-weight: 400;
		    transition: left .2s ease-out;
		}
	}
	&:hover{
		.rex-btn-text-icon {
			i{
				left: 100%;
		    }
		    span {
		     	left: 100%;
		    }
	    }
        &:hover .rex-btn-text-icon{
    		left: 0;
    		&:before{
    			left: 0;
    			font-weight: normal;
    			font-style: normal;
    			font-variant: normal;
    			font-stretch: normal;
    			-webkit-font-smoothing: antialiased;
    		}
    	}
	}
}
.rex-bottom-small,.rex-bottom-medium,.rex-bottom-large,.rex-bottom-x-large.rex-btn-icon {
	.rex-btn-text-icon i{
		font-size: 18px;
	}
	.rex-btn-text-icon i,
	.rex-btn-text-icon span{
		display: block;
	    position: relative;
	    left: 0;
	    line-height: inherit;
	    transition: left .2s ease-out;
	}
}

.rex-bottom-small{
	height: 52px;
    line-height: 52px;
    font-size: 13px;
    .rex-btn-text{
    	padding: 0 50px;
    }
	.rex-btn-text-icon{
		width: 50px;
	}
}
.rex-bottom-medium{
	height: auto;
    line-height: 40px;
    font-size: 18px;
    .rex-btn-text{
    	padding: 0 30px;
    }
	.rex-btn-text-icon{
		width: 60px;
	}
}
.rex-bottom-large{
	height: 52px;
    line-height: 52px;
    font-size: 18px;
    .rex-btn-text{
    	padding: 0 75px;
    }
	.rex-btn-text-icon{
		width: 70px;
	}
}
.rex-bottom-x-large{
	height: 80px;
    line-height: 80px;
    font-size: 22px;
    .rex-btn-text{
    	padding: 0 90px;
    }
	.rex-btn-text-icon{
		width: 80px;
	}
}

.btn-text{
    font-size: 14px !important;
	&:before{
		display: none !important;
	}
}