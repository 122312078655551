

	.Counter{
		background: url("../img/lined_paper.png");
		border-top: 1px solid $gray-lignt;
		border-bottom: 1px solid $gray-lignt;
		transition: 0.5s;
		h5{
			font-size: 18px;
			margin: 3px 0;
			text-transform: uppercase;
		}
		.col-md-4{
			overflow: hidden;
			padding-bottom: 20px;
		}
		.icon-style{
			margin: 0 auto;
			i{
				color: $white-color;
			}
		}
		.counter, span{
			color: $brand-primary;
			font-size: 30px;
			display: inline;
			font-family: $font-family-sans-serif-montserrat;
			font-weight: 700;
		}
		.content{
			overflow: hidden;
			padding-top: 10px;
		}
		.Counter-last-child{
			padding-bottom: 0;
			.content{
				padding-bottom: 0;
			}
			.counter-box{
			    transform: translateY(-2px);
			}
		}
	}

	.counter-box{
		background: $white-color;
		padding: 20px 26px;
		box-shadow: 0px 2px 6px rgba(232, 232, 232, 0.90);
	    min-height: 108px;
		.content{
			padding: 0;
			padding-top: 10px;
		}
	}