

.menu-container {
    width: 80%;
    margin: 0 auto;
    background: #e9e9e9;
}

.menu-mobile {
    display: none;
    padding: 20px;
    &:after {
        content: "\f394";
        font-family: "Ionicons";
        font-size: 2.5rem;
        padding: 0;
        float: right;
        position: relative;
        top: 50%;
        transform: translateY(-25%);
    }
}

.menu-dropdown-icon {
    &:before {
        content: "\f489";
        font-family: "Ionicons";
        display: none;
        cursor: pointer;
        float: right;
        padding: 1.5em 2em;
        background: rgb(249, 249, 249);
        color: #333;
    }
}

.menu {
    h6{
        padding-bottom: 5px;
        text-transform: uppercase;
    }
    > ul {
        margin: 0 auto;
        width: 100%;
        list-style: none;
        padding: 0;
        position: relative;
        //position: relative;
        /* IF .menu position=relative -> ul = container width, ELSE ul = 100% width */
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &:before,
        &:after {
            content: "";
            display: table;
        }
        &:after {
            clear: both;
        }
        > li {
            float: left;
            background: #e9e9e9;
            padding: 0;
            margin: 0;
            a {
                text-decoration: none;
                padding: 1.5em 3em;
                display: block;
            }
            &:hover {
                background: #f0f0f0;
            }
            > ul {
                display: none;
                width: 100%;
                background: #f0f0f0;
                padding: 20px 45px;
                position: absolute;
                z-index: 99;
                left: 0;
                margin: 0;
                list-style: none;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                &:before,
                &:after {
                    content: "";
                    display: table;
                }
                &:after {
                    clear: both;
                }
                > li {
                    margin: 0;
                    padding-bottom: 0;
                    list-style: none;
                    width: 25%;
                    background: none;
                    float: left;
                    margin-right: 12.5%;
                    &:last-child{
                        margin-right: 0;
                    }
                    a {
                        color: #777;
                        padding: 1em 0;
                        // padding: .2em 0;
                        width: 100%;
                        display: block;
                        border-bottom: 1px solid #ccc;
                    }
                    > ul {
                        display: block;
                        padding: 0;
                        margin: 10px 0 0;
                        list-style: none;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        &:before,
                        &:after {
                            content: "";
                            display: table;
                        }
                        &:after {
                            clear: both;
                        }
                        > li {
                            float: left;
                            width: 100%;
                            padding: 3px 0;
                            margin: 0;
                            font-size: 12px;
                            &:last-child{
                                border-color: transparent;
                            }
                            a {
                                border: 0;
                            }
                        }
                    }
                }
                &.normal-sub {
                    width: 200px;
                    left: auto;
                    padding: 10px 20px;
                    > li {
                        width: 100%;
                        a {
                            border: 0;
                            padding: 1em 0;
                        }
                    }
                }
            }
        }
    }
}


/* ––––––––––––––––––––––––––––––––––––––––––––––––––
Mobile style's
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media only screen and (max-width: 959px) {
    .menu-container {
        width: 100%;
    }
    .menu-mobile {
        display: block;
    }
    .menu-dropdown-icon {
        &:before {
            display: block;
        }
    }
    .menu {
        > ul {
            display: none;
            > li {
                width: 100%;
                float: none;
                display: block;
                a {
                    padding: 1.5em;
                    width: 100%;
                    display: block;
                }
                > ul {
                    position: relative;
                    &.normal-sub {
                        width: 100%;
                    }
                    > li {
                        float: none;
                        width: 100%;
                        margin-top: 20px;
                        &:first-child {
                            margin: 0;
                        }
                        > ul {
                            position: relative;
                            > li {
                                float: none;
                            }
                        }
                    }
                }
            }
        }
        .show-on-mobile {
            display: block;
        }
    }
}

/* Coustom style */

.logo{
	text-align: center;
	padding: 20px 0;
    z-index: 11;
    position: relative;
    img{
        margin: 0 auto;
    }
}

.menu-container{
	width: 100%;
}

.menu-mobile {
	padding: 15px 60px 15px 10px;
	text-indent: -99999px;
	&:after {
		float: left;
	    text-indent: 0px;
	    transform: translateY(0);
        color: $icon-color;
	}
}
.menu-container{
	position: relative;
	background: transparent;
}

ul.main-ul{
	li{
		background: transparent;
		&:hover{
			background: transparent;
		}
		ul{
			background: $dark-light;
		}
	}
	a{
		color: $text-color;
		font-family: $font-family-sans-serif-montserrat;
	    text-transform: uppercase;
	}
}


.menu-search{
    // padding: 5px 22px;
    position: absolute;
    top: 0;
    right: 0;
    width: 87%;
    .btn-default{
    	border: 0;
        float: right;
        color: $text-color;
    	margin-top: 10px;
    	background: transparent;
    	i{
    		transition: 0.5s;
    	}
    }
    .btn-default:hover{
		i{
			color: $brand-primary;
		}
    }
    .btn-default:focus{
    	outline: none;
    	box-shadow: none;
    	background: none;
    }
	.search-input{
		width: 100%;
        padding: 0 25px 0 0;
		// padding: 0 20px;
	    top: 50%;
	    position: absolute;
	    transform: translateY(-50%);
	    input{
            font-size: 12px;
	    	height: 34px;
	    	padding: 0 59px 0 20px;
	    }
	}
	.search-btn{
        margin-top: 20px;
        height: 100%;
        font-size: 14px;
        line-height: 1;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        position: absolute;
        margin: 0;
        right: 25px;
        top: 0;
	}
    .rex-bottom-medium .rex-btn-text{
        padding: 0 15px;
        // padding: 0 20px;
    }
}



/* Oerlay Style
--------------------*/

.overlay {
    height: 0%;
    width: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    background-color: #fff;
    // background-color: rgba(255,255,255, 0.5);
    overflow-y: hidden;
    transition: 0.5s;
}

.overlay {
    &-webkit-scrollbar {
        overflow: -moz-scrollbars-vertical;
    }
}


.overlay a {
    // padding: 8px;
    padding: 0;
    text-decoration: none;
    color: #818181;
    display: block;
    transition: 0.3s;

    position: absolute;
    top: 10px;
    right: 0;
    z-index: 10;
}

.overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
}

.closebtn {
    position: absolute;
    top: 0;
    right: 30px;
    font-size: 38px !important;
}


@media screen and (max-height: 450px) {
  .overlay {overflow-y: auto;}
  .closebtn {
    font-size: 40px !important;
    top: 15px;
    right: 35px;
  }
}

