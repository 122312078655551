
/**
 * -----------------------------------------------------------------*
 *       Main Style Sheet for the Creative EduRex Template          *
 * -----------------------------------------------------------------*
 *
 * Project:	EduRex
 * Version:	1.0
 * Last change:	02/02/2016 [improved documentation]
 * Author: RexTheme
 *	
 *
 * -----------------------------------------------------------------*
 *                         TABLE OF CONTENTS                        *
 * -----------------------------------------------------------------*
 *1. All page
    * 1.1. Base Styles
	* 1.2. Header
	* 1.3. Navigation
	* 1.4. Footer Styles
    * 1.5. Responsive Styles
 *2.Blog left sidebar
 *2.Blog Right sidebar
 *2.Blog no sidebar
 *2.Blog singel-blog-left
 *2.Blog singel-blog-right
 *2.Blog singel no sidebar  


 * -----------------------------------------------------------------*
 *                        Color Codes                               *
 * -----------------------------------------------------------------*


$gray-base:              #000 ;
$gray-darker:            lighten($gray-base, 13.5%) ; 
$gray-dark:              lighten($gray-base, 20%) ;   
$gray:                   lighten($gray-base, 33.5%) ; 
$gray-light:             lighten($gray-base, 46.7%) ; 
$gray-lighter:           lighten($gray-base, 93.5%) ; 

$brand-primary:         #00b3ee ; // #337ab7
$secondary-color:       #333333;
$white-color:       	#fff;
$dark-light:       		#f9f9f9;
$light-dark:       		#515f67;
$brand-success:         #5cb85c ;
$brand-info:            #5bc0de ;
$brand-warning:         #f0ad4e ;
$brand-danger:          #d9534f ;
$dark-bg :           	#293136;
$gray-bg :           	#f9f9f9;
// border-color
$gray-lignt:			#e8e8e8;
// icon color
$icon-color:			#393939;

//** Background color for `<body>`.
	$body-bg:               #fff ;

//** Global text color on `<body>`.
	$text-color:            #797979 ;


* ------------------------------------------------------------------*
*                      Typography                                   *
* ------------------------------------------------------------------*

$font-family-sans-serif:  'Raleway', sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;

//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
	$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;

// montserrat font
	$font-family-sans-serif-montserrat: 'Montserrat', sans-serif !important;
	$font-family-base-montserrat:    $font-family-sans-serif-montserrat;

$font-family-base:        $font-family-sans-serif !default;
$font-size-base:          14px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; 
$font-size-small:         ceil(($font-size-base * 0.85)) !default; 

$font-size-h1:            40px !default; 
$font-size-h2:            32px !default; 
$font-size-h3:            26px !default; 
$font-size-h4:            22px !default; 
$font-size-h5:            18px !default;
$font-size-h6:            14px !default; 


* --------------------------------------------------------------------*/


//  Rex custom color 
@import "layout/variables-custom";
@import "layout/rex-base";

//  Rex theme Style 
@import "layout/header";
@import "layout/footer";
@import "layout/nav";
@import "layout/widget";
@import "layout/breadcum";

// home pages style
@import "layout/slider";
@import "layout/features";
@import "layout/About_Us";
@import "layout/popular_courses";
@import "layout/testimonials";
@import "layout/Instructors";
@import "layout/call_to_action";
@import "layout/Counter";
@import "layout/newsletter";
@import "layout/latest_news";
@import "layout/partners";
// end home page style

// About page style
@import "layout/out_mission";

// Instructors-page
@import "layout/Instructors-page";

// Courses Page
@import "layout/courses";

// Single Courses page
@import "layout/single-courses";

// Single Courses page
@import "layout/contact";

// blog_page
@import "layout/blog_page";

// single-blog page
@import "layout/single-blog";

// help page
@import "layout/help";
@import "modules/button";
// responsive style
@import "layout/responsive";


