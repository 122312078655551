

	.rex-slider{
		h1, p{
			color: $white-color;			
			margin: 0;
		}

		h1{
			line-height: 1;
			width: 100%;
			font-size: 100%;
			text-align: center;
			top: 50% !important;
			text-transform: uppercase;
			transform: translateY(-60%);
		}
		p{
			font-size: 100%;
			width: 100%;
			text-transform: none;
			font-weight: 400;
			display: none;
		}
		a{
			display: none;
		}

		.slider-text{
			width: 100%;
			text-align: center;
			top: 50% !important;
			transform: translateY(60%);
		}

		.slide-list{
			position: relative;
		    // min-height: 50vh;
			&:before{
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0, 0.50);
			}
		}
	}

	.slider-progress{
		z-index: 2 !important;
		bottom: auto !important;
		top: 0 !important;
		height: 0.8% !important;
	}

	.highlight-text{
		display: block;
		color: $brand-primary;
	}

