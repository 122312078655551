/**
 * ---------------------------------------------------------------------------
 * 0?. Footer Styles
 * ---------------------------------------------------------------------------
 */

footer{
	background: $dark-bg;
	padding-top: 20px;
	// padding-top: 80px;
	text-align: center;
	h3{
		font-weight: 400;
		// padding-bottom: 15px;
		padding-bottom: 5px;
		font-size: 18px;
		margin-top: 0;
	}
	p{
		color: $text-color-footer;
		// text-align: center !important;
	}
	a{
		color: $brand-primary;
	}

	h1,h2,h3,h4,h5,h6{
		text-transform: uppercase;
		color: $white-color;
	}

	.email-add{
		&:hover{
			text-decoration: underline !important;
		}
	}

	.phone-number{
		display: inline-block;
		br{
			display: none;
		}
		a{
		    margin: 0 5px;
	        display: block;
			color: $text-color-footer;
		}
	}
	.copyright{
		padding: 20px 0;
		// margin-top: 30px;
		margin-top: 10px;
		border-top: 1px solid #363f44;
		p{
			margin: 0;
			text-align: center;
		}
	}

	.footer-section{
		// margin-top: 30px;
	    // padding-bottom: 30px
	    margin-top: 15px;
	    padding-bottom: 15px;
	    border-bottom: 1px solid #363F44;
	    &:first-child{
	    	padding-top: 0;
		    margin-top: 0;
	    }
	    &:last-child{
	    	border-color: transparent;
	    	padding-bottom: 0;
	    }
	}

	.faq-section{
		li{
			padding: 5px 0;
			display: none;
		    color: $text-color-footer;
		    &:first-child{
		    	display: block;
		    }
		    a{
		    	color: $text-color-footer;
		    	&:hover{
		    		color: $brand-primary;
		    	}
		    }
		}
	}
	.address{
	    text-align: left;
	    h6{
	    	min-width: 70px;
	    }
		h6,p{
			display: inline-block;
		}
	}
	.instructors-media-link{
		padding-top: 18px;
	}

	.usefull-link li{
		padding: 2px 4px;
	}
}

.footer-description{
	padding-top: 20px;
	p{
		padding: 10px 0;
		margin: 0;
		display: none;
	}
	.instructors-media-link a{
		margin-right: 10px;
		&:last-child{
			margin-right: 0;
		}
	}
}
