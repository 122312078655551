.courses_page{
    .block-content{
        &:hover{
            transform: translateY(0);
            border-color: #01B3EE;
            box-shadow: none;
            .courses-overlay{
                opacity: 1;
                transform: translate(-50%, -50%);
            }
            .courses-img:before{
                opacity: 1;
            }
        }
        .entry-meta{
            // text-align: left;
            padding-top: 6px;
            margin-top: 6px;
        }
        h6{
            line-height: 24px;
        }
    }
    .dropdown-menu {
        padding: 0;
    }
    .dropdown-menu > li > a{
        padding: 10px 20px;
    }
}


.courses_page_btn2{
    text-align: center;
}

.list-group{
    margin-bottom: 0;
    .block-content{
        // margin-bottom: 0;
        margin: 0 0 20px;
        img{
            width: 100%;
        }
    }
}

.courses-slider{
    .list-group{
        margin-left: 0;
    }
}

.list-group-item{
    padding: 0 15px;
    border-radius: 0;
    border: transparent;
    background: transparent !important;
    // transition: 1.5s;
    .block-content{
        display: flex;
        margin-bottom: 50px;
        img{
            width: inherit;
            // margin-right: 30px;
        }
        .entry-meta{
            text-align: left;
            padding-top: 5px;
            margin-top: 7px;
        }
        .content {
            padding: 40px 30px 29px;
            width: 100%;
            .description{
                padding-top: 20px;
            }
        }
    }
    .description{
        display: block;
    }
    .img-hover{
        img{
            min-width: 390px;
            display: block;
            height: auto;
        }
    }
}

.well-sm{
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    margin: 0;
    padding: 0;
    // display: flex;
    padding-bottom: 20px;
    p{
        padding-bottom: 20px;
    }
    span{
        color: $brand-primary;
    }
    i{
        font-size: 18px;
    }
}

.courses_page_btn{
    padding: 12px;
    border-radius: 4px !important;
    margin-bottom: 10px;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    color: $text-color;
    transition: 0.5s;
    &:hover, &:focus, &:active{
        background: none;
        box-shadow: none;
        color: $brand-primary;
        border-color: $brand-primary;
    }
    i{
        font-size: 14px;
        margin-left: 5px;
    }
}

.btn-group.open .dropdown-toggle{
    background: none !important;
    box-shadow: none !important;
}

.btn-sm{
    background: none;
    border: 0;
    border-right: 1px solid $gray-lignt;
    padding: 0 14px;
    border-radius: 0 !important;
    &:last-child{
        border-color: transparent;
    }
    &:hover,&:focus,&:active{
        background: none !important;
        box-shadow: none !important;
        outline: none;
    }
}

.sorting-btn{
    display: none;
}

a#list:focus,
a#grid:focus{
    i{
        color: $brand-primary;
    }
}
    
.label{
    margin-left: 8px;
    transform: translateY(-2px);
    display: inline-block;
    text-transform: uppercase;
    padding: 1px;
    border-radius: 0;
    line-height: 1;
    font-size: 10px;
    font-weight: 200;
}

.tag{
    // background: red;
    position: absolute;
    right: 0;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    text-align: center;
    overflow: hidden;
    &:before{
        // content: "\f02e";
        // font-family: FontAwesome;
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 100px solid $brand-primary;
        border-left: 100px solid transparent;
        transform: translate(-21px, -4px);
    }
    i{
        position: relative;
        margin: 0 !important;
        transform: translateX(8px);
        color: $white-color !important;
    }
}



.total-courses{
    padding: 17px 0; 
    p{
        padding: 0;
        margin: 0;
        font-size: 16px;
    }
}


// List Grid View

.glyphicon { margin-right:5px; }
.thumbnail
{
    margin-bottom: 20px;
    padding: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

.item.list-group-item
{
    float: none;
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
}
.item.list-group-item:nth-of-type(odd):hover,.item.list-group-item:hover
{
    background: #428bca;
}

.item.list-group-item .list-group-image
{
    margin-right: 10px;
}
.item.list-group-item .thumbnail
{
    margin-bottom: 0px;
}
.item.list-group-item .caption
{
    padding: 9px 9px 0px 9px;
}
.item.list-group-item:nth-of-type(odd)
{
    background: #eeeeee;
}

.item.list-group-item:before, .item.list-group-item:after
{
    display: table;
    content: " ";
}

.item.list-group-item img
{
    float: left;
}
.item.list-group-item:after
{
    clear: both;
}
.list-group-item-text
{
    margin: 0 0 11px;
}

.courses-list{
    .courses-img{
        padding: 0;
        img{
            min-height: 213px;
        }
    }
}

/* list-group-page */
.list-group-page{
    margin-bottom: 0;
    .courses-img{
        img{
            min-height: auto;
        }
    }
}


/* courses-slider */

.courses-slider{
    .item{
        padding: 0 15px;
    }
    .owl-controls{
        padding: 0 40px;
        transform: translateY(0%);
        top: 35%;
        width: 100%;
        text-align: left;
    }
    .owl-next{
        float: right;
    }
    .list-group .description{
        display: block;
    }
}

/*----------------------
  Single Course page  
-------------------------*/


.single-course-img{
    margin-bottom: 39px;
}

.single-instructor .sidebar-title-section h2, 
.single-course .sidebar-title-section h2 {
    margin-top: 0;
}
