

	.contact-us{
		.title-section{
			h3{
				margin: 0;
				padding-top: 0;
			}
			p{
			    font-size: 14px;
			    padding: 0;
			    &:before{
			    	display: none;
			    }
			}
		}
		.rex-forms{
			padding-top: 20px;
			text-align: center;
		}
	}

	.rex-map{
		padding-bottom: 20px;
	}

	#googleMap{
		width: 100%;
		min-height: 400px;
	    filter: grayscale(100%);
	}

	.media-link{
		margin-bottom: 25px;
	}
	.addres{
		&:nth-child(even){
			padding: 25px 0;
			margin: 25px 0;
			border-top: 1px solid $gray-lignt;
			border-bottom: 1px solid $gray-lignt;
			a{
				color: $brand-primary;
			}
		}
	}
	.addres-icon{
		margin-bottom: 10px;
		i{
			font-size: 24px;
			color: $brand-primary;
		}
	}
	.addres-content{
		overflow: hidden;
	    transform: translateY(-4px);
	}

	.address-phone{
		a{
			color: $text-color;
		}
	}
