
	.our-difference{
		.about-content{
			padding-top: 0;
		}
		.panel-group{
			margin-bottom: 0;
		}
	}

	.partners{
		background: $dark-light;
		padding: 57px 0;
		.partners-logo{
			min-height: 140px;
			margin-bottom: 20px;
			position: relative;
			background: $white-color;
			border: 1px solid transparent;
			transition: 0.5s;
		}
		.partners-content{
			img{
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
			}
		}
		a{
			&:hover{
				.partners-logo{
					border-color: $brand-primary;
				}
			}
		}
		.partners-last-child{
			margin-bottom: 0;
		}
	}

	.nav-tabs{
		h6{
			text-transform: uppercase;
		}
	}
	.rx-panel-title{
		text-transform: uppercase;
	    font-size: 14px;
	}
