

	.out_mission{
		br{
			display: none;
		}
		h4{
			text-transform: uppercase;
		}
		span{
			color: $brand-primary;
			font-family: $font-family-sans-serif-montserrat;
			font-weight: 700;
			text-transform: uppercase;
		}
		img{
			margin: 0 auto;
		}
		.title-section{
			padding-bottom: 15px;
		}
		.content-left{
			padding-top: 20px;
			p{
				margin: 0;
			}
		}
		.mission-description{
			padding-bottom: 0;
		}
		.content-list{
			padding: 24px 0;
			text-align: left;
			display: inline-block;
			li{
				padding: 11px 0;
				padding-left: 25px;
				position: relative;
			    line-height: 20px;
				&:before{
					content: "\f061";
					position: absolute;
					top: 50%;
					left: 0;
					color: $brand-primary;
					font-size: 12px;
					font-family: FontAwesome;
					transform: translateY(-50%)
				}
			}
		}
	}
