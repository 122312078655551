

	.call_to_action{
		background-repeat: no-repeat;
	    background-attachment: fixed;
		background-position: center;
	    background-size: cover;
		position: relative;
		// padding-top: 85px;
		width: 100%;
		&:before{
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0, 0.5);

		}
		h1,h4{
			color: $white-color;
		}
		h1{
			margin-top: 0;
			font-size: 20px;
			line-height: 25px;
			text-transform: uppercase;
		}
		h4{
			font-size: 18px;
			line-height: 28px;
			font-weight: 400;
			padding: 20px 0;
		}
		br{
			display: none;
		}
	}