

	.blog-page{
		.blog-title{
			// padding-bottom: 15px;
			h3{
				margin-top: 0;
				font-size: 100%;
				line-height: 1.2;
			    margin-bottom: 13px;
				text-transform: uppercase;
			}
		}

		.blog-content{
			background: $gray-bg;
			// padding: 40px 40px 20px;
			padding: 20px;
			margin-bottom: 50px;
			border-bottom: 1px solid $gray-lignt;
		}

		.block-date{
		    margin-bottom: 15px;
		}

		.read-more{
		    a:hover{
		    	text-decoration: underline !important;
		    }
		}
		.share_section{
			padding-top: 40px;
			margin-top: 12px;
			.media-link{
				padding-bottom: 0;
				margin: 0;
			}
		}
		.media-section{
			padding: 20px 0 ;
		}
		.single-img{
			margin-bottom: 0;
		}

		.description{
			padding-bottom: 0;
		}
	}

	.blog-title{
		a{
			h3{
				transition: 0.5s;
			}
			&:hover{
				h3{
					color: $brand-primary;
				}
			}
		}
	}

	.blog-list-section{
		.blog-content{
			transition: 0.5s;
		}
		&:hover{
			.blog-content{
				border-color: $brand-primary;
			}
			.block-date{
				p{
					color: $white-color;
				}
				&:before{
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					margin: 0;
					opacity: 1;
					border-radius: 0;
				}
			}
		}
	}

	.pagination-center{
		text-align: center;
	}
	
	.author-top{
		display: block;
	}
	.author-bottom{
		display: none;
	}