
	.newsletter{
		padding: 0 0 56px;
		.title-section{
			text-align: center;
			padding: 0 15px 19px;
			p:before{
				display: none;
			}
		}
	}

	.newsletter-area{
		padding: 69px 15px;
		border-radius: 3px;
		text-align: center;
		border: 1px solid $gray-lignt;
		background: url("../img/lined_paper.png");
		position: relative;
		&:before,
		&:after{
			content: "";
			position: absolute;
			top: 0;
			width: 8px;
			height: 100%;
		}
		&:before{
			left: 0;
			background: url("../img/envelope.png");
		}
		&:after{
			right: 0;
			background: url("../img/envelope.png");
		}
	}
