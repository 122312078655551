/**
 * ---------------------------------------------------------------------------
 * 0?. Header Styles
 * ---------------------------------------------------------------------------
 */

 header{
 		background: $gray-bg;
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		border-bottom: 1px solid #f1f1f1;
		padding: 5px 0;
	p{
		margin-bottom: 0;
		margin-right: 10px;
		padding-right: 10px;
		display: inline-block;
		border-right: 1px solid $gray-lignt;
	}
	.btn{
		background: none;
		border: transparent;
		transition: 0.5s;
		&:active,&:focus{
			box-shadow: none !important;
			background-color: transparent !important;
		}
	}
	.dropdown-menu{
		// left: -40px;
		padding: 5px;
	}
	.block{
		padding: 0;
		text-align: center;
		a{
			color: $text-color;
		    display: inline-block;
		    margin-right: 10px;
			&:last-child{
				border: transparent;
				margin-right: 0;
				padding-right: 0;
			}
			&:focus{
				outline: none;
			}
		}
		i{
			margin-right: 10px;
		}
		button{
			font-weight: 600;
			color: $text-color;
		}
		.dropdown-toggle{
			&:hover{
				background: transparent;
				color: $brand-primary;
			}
		}
		.dropdown-menu{
			min-width: auto;
			padding: 0;
			border-radius: 0;
			text-align: center;
			display: block;
			opacity: 0;
		    transform: translateY(120px);
		    z-index: 12;
			a{
				font-size: 10px;
				padding: 5px 15px;
				&:hover{
					background: transparent;
				}
			}
			li{
				border-bottom: 1px solid #ddd;
				&:hover{
					a{
						color: $brand-primary;
					}
				}
			}
		}
		.open{
			.dropdown-menu{
				opacity: 1;
				transition: 0.5s;
				transform: translateY(0);
			}
		}
	}
 }
