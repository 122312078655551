

	.features{
		text-align: center;
		h5{
			padding: 20px 0 10px;
			transition: 0.5s;
			text-transform: uppercase;
			position: relative;
			&:before{
				content: "";
				position: absolute;
				left: 50%;
				bottom: 0;
				width: 50px;
				height: 1px;
				background: $brand-primary;
				transition: 0.5s;
				opacity: 0;
			}
		}
		p{
			padding-bottom: 20px;
			position: relative;
			z-index: 10
		}
	}

	.features-list{
		padding: 15px 20px;
		margin-bottom: 20px;
	}

	.features-icon{
		position: relative;
		z-index: 10;
		i{
			font-size: 36px;
			color: $icon-color;
		}
	}
